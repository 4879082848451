import Loading from '@local/frontend/components/atoms/Loading';
import {usePostHog} from 'posthog-js/react';
import React, {FC, useEffect, useState} from 'react';

const PostHogFeatureFlagsWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const posthog = usePostHog();
  const [flagsLoaded, setFlagsLoaded] = useState<boolean>(false);

  useEffect(() => {
    posthog.onFeatureFlags(() => {
      // feature flags are guaranteed to be available at this point
      setFlagsLoaded(true);
    });
  }, []);

  if (!flagsLoaded) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default PostHogFeatureFlagsWrapper;
