import React, {lazy} from 'react';
import {Navigate, Outlet, RouteObject} from 'react-router-dom';
import prefetchQueriesForGroupPaymentLeaveRoute from '@local/frontend/prefetchers/prefetch-group-payment-leave-route';
import prefetchQueriesForGroupPaymentTerminalRoute from '@local/frontend/prefetchers/prefetch-group-payment-terminal-route';
import prefetchQueriesForKickedRoute from '@local/frontend/prefetchers/prefetch-kicked-route';
import NotificationProvider from '@local/frontend/providers/NotificationProvider';
import PrefetchWrapper from '@local/frontend/wrappers/PrefetchWrapper';
import RelevantCheckoutIdsContext from '@local/frontend/wrappers/RelevantCheckoutIdsContext';
import GroupPaymentProvider from '@local/frontend/providers/GroupPaymentProvider';
import GroupPaymentRedirectWrapper from '@local/frontend/providers/GroupPaymentRedirectWrapper';
import prefetchQueriesForSbiRoute from '@local/frontend/prefetchers/prefetch-sbi-route';
import SbiProvider from '@local/frontend/providers/SbiProvider';
import GroupPaymentWebsocketWrapper from '@local/frontend/providers/GroupPaymentWebsocketWrapper';
import UpdateGroupPaymentMutationProvider from '@local/frontend/providers/UpdateGroupPaymentMutationProvider';
import ModalProvider from '@local/frontend/libs/modals/ModalProvider';
import {PostHogFeature, useFeatureFlagVariantKey} from 'posthog-js/react';
import {featureFlags} from '@local/frontend/feature-flags';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import getSbiRoute from '@local/frontend/util/getSbiRoute';
import {SplitType} from '@local/backend/@types/updated-api-types/group-payments/SplitType';
import GroupPaymentHelper from '@local/frontend/util/GroupPaymentHelper';
import PostHogFeatureFlagsWrapper from '@local/frontend/providers/posthog/PostHogFeatureFlagsWrapper';
import PostHogGroupWrapper from '@local/frontend/providers/posthog/PostHogGroupWrapper';
import {SbiV2Steps} from './split-by-item/@types/SbiV2Steps';

const GroupPaymentSetupPage = lazy(
  () =>
    import(
      /* webpackChunkName: "group-setup-page" */ './group-setup/GroupPaymentSetupPage'
    )
);

const GroupPaymentDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "group-dashboard-page" */ './group-dashboard/GroupPaymentDashboardPage'
    )
);

const GroupPaymentLeaversPage = lazy(
  () =>
    import(
      /* webpackChunkName: "group-leave-page" */ './group-terminal/GroupPaymentLeaversPage'
    )
);

const GroupPaymentKickedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "group-kicked-page" */ './group-terminal/GroupPaymentKickedPage'
    )
);

const GroupPaymentTerminalPage = lazy(
  () =>
    import(
      /* webpackChunkName: "group-terminal" */ './group-terminal/GroupPaymentTerminalPage'
    )
);

const GroupPayOnBehalf = lazy(
  () =>
    import(
      /* webpackChunkName: "group-pay-on-behalf" */ './group-pay-on-behalf/GroupPayOnBehalfValidationWrapper'
    )
);

const GroupPaymentSbiPage = lazy(
  () =>
    import(
      /* webpackChunkName: "group-payment-sbi-page" */ './split-by-item/GroupPaymentSbiPage'
    )
);

const GroupPaymentSbiMergedFlowPage = lazy(
  () =>
    import(
      /* webpackChunkName: "group-payment-sbi-merged-flow-page" */ './split-by-item/merged-flow/GroupPaymentSbiMergedFlowPage'
    )
);

const GroupPaymentNoDashboardNavigator = () => {
  const groupPayment = useCachedGroupPayment();
  const customerId = useCustomerId();
  const skipVerifyAndAssignVariants = useFeatureFlagVariantKey(
    featureFlags.skipVerifyAndAssign.key
  );

  const hasAllItemsBeenAssigned = new GroupPaymentHelper(
    groupPayment
  ).hasAllItemsBeenAllocated();

  const shouldRedirectToDashboard =
    skipVerifyAndAssignVariants ===
      featureFlags.skipVerifyAndAssign.variants.skipVerifyAndAssign &&
    groupPayment.memberIds.concat(groupPayment.invited ?? []).length > 1 &&
    hasAllItemsBeenAssigned;

  const hasJoined = !!customerId && groupPayment.memberIds.includes(customerId);

  if (skipVerifyAndAssignVariants === undefined) {
    return null;
  }

  if (groupPayment.splitType !== SplitType.BY_ITEM) {
    return <GroupPaymentDashboardPage />;
  }

  if (shouldRedirectToDashboard && hasJoined) {
    return (
      <Navigate
        replace
        to={`${getSbiRoute(groupPayment.id, groupPayment.merchantId, customerId)}&step=${SbiV2Steps.GROUP_OVERVIEW}`}
      />
    );
  }

  return (
    <Navigate
      replace
      to={`${getSbiRoute(groupPayment.id, groupPayment.merchantId, customerId)}`}
    />
  );
};

const GroupPaymentSbiRouteNavigator = () => {
  const splitByItemFlowVariant = useFeatureFlagVariantKey(
    featureFlags.splitByItem.key
  );

  if (splitByItemFlowVariant === undefined) {
    return null;
  }

  if (splitByItemFlowVariant === featureFlags.splitByItem.variants.control) {
    return <GroupPaymentSbiPage />;
  }

  return <GroupPaymentSbiMergedFlowPage />;
};

export const groupPaymentRoutes: RouteObject = {
  path: 'g',
  element: (
    <NotificationProvider>
      <Outlet />
    </NotificationProvider>
  ),
  children: [
    {
      path: ':groupPaymentId',
      element: (
        <PostHogFeatureFlagsWrapper>
          <PostHogGroupWrapper>
            <GroupPaymentProvider>
              <SbiProvider>
                <UpdateGroupPaymentMutationProvider>
                  <ModalProvider>
                    <Outlet />
                  </ModalProvider>
                </UpdateGroupPaymentMutationProvider>
              </SbiProvider>
            </GroupPaymentProvider>
          </PostHogGroupWrapper>
        </PostHogFeatureFlagsWrapper>
      ),
      children: [
        {
          path: 'sbi',
          element: (
            <GroupPaymentRedirectWrapper>
              <RelevantCheckoutIdsContext>
                <GroupPaymentWebsocketWrapper>
                  <PrefetchWrapper prefetch={prefetchQueriesForSbiRoute}>
                    <GroupPaymentSbiRouteNavigator />
                  </PrefetchWrapper>
                </GroupPaymentWebsocketWrapper>
              </RelevantCheckoutIdsContext>
            </GroupPaymentRedirectWrapper>
          ),
        },
        {
          path: 'group-setup',
          element: (
            <GroupPaymentRedirectWrapper>
              <RelevantCheckoutIdsContext>
                <GroupPaymentWebsocketWrapper>
                  <GroupPaymentSetupPage />
                </GroupPaymentWebsocketWrapper>
              </RelevantCheckoutIdsContext>
            </GroupPaymentRedirectWrapper>
          ),
        },
        {
          index: true,
          path: 'group-dashboard',
          element: (
            <GroupPaymentRedirectWrapper>
              <RelevantCheckoutIdsContext>
                <GroupPaymentWebsocketWrapper>
                  <PostHogFeature
                    flag={featureFlags.noDashboard.key}
                    match={featureFlags.noDashboard.variants.control}
                    fallback={<GroupPaymentNoDashboardNavigator />}
                  >
                    <GroupPaymentDashboardPage />
                  </PostHogFeature>
                </GroupPaymentWebsocketWrapper>
              </RelevantCheckoutIdsContext>
            </GroupPaymentRedirectWrapper>
          ),
        },
        {
          path: 'group-leave',
          element: (
            <RelevantCheckoutIdsContext>
              <PrefetchWrapper
                prefetch={prefetchQueriesForGroupPaymentLeaveRoute}
              >
                <GroupPaymentLeaversPage />
              </PrefetchWrapper>
            </RelevantCheckoutIdsContext>
          ),
        },
        {
          path: 'group-kicked',
          element: (
            <RelevantCheckoutIdsContext>
              <PrefetchWrapper prefetch={prefetchQueriesForKickedRoute}>
                <GroupPaymentKickedPage />
              </PrefetchWrapper>
            </RelevantCheckoutIdsContext>
          ),
        },
        {
          path: 'pay-on-behalf',
          element: (
            <GroupPaymentRedirectWrapper>
              <RelevantCheckoutIdsContext>
                <GroupPaymentWebsocketWrapper>
                  <GroupPayOnBehalf />
                </GroupPaymentWebsocketWrapper>
              </RelevantCheckoutIdsContext>
            </GroupPaymentRedirectWrapper>
          ),
        },
        {
          path: 't',
          element: (
            <GroupPaymentRedirectWrapper>
              <RelevantCheckoutIdsContext>
                <PrefetchWrapper
                  prefetch={prefetchQueriesForGroupPaymentTerminalRoute}
                >
                  {/* @TODO: need to test to see if these need any validation. can trying to fetch the merchant fail if the access token expired? */}
                  <GroupPaymentTerminalPage />
                </PrefetchWrapper>
              </RelevantCheckoutIdsContext>
            </GroupPaymentRedirectWrapper>
          ),
        },
      ],
    },
  ],
};
